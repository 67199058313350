@import '@/styles/common.scss';

.el-table {
  /deep/ .el-table__body-wrapper {
    overflow-x: scroll !important; //直接设置横轴内容滚动条 即可撑高高度 还不影响使用
    z-index: 2;
  }
}

/* 设置滚动条宽度和高度 */
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px; /* 横向滚动条 */
  height: 8px; /* 纵向滚动条 必写 */
}
/* 设置滚动条样式 */
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: hsla(220, 4%, 58%, 0.3);
  transition: background-color 0.3s;
}

/* 设置滚动条样式 */
/deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
  background: #ededed;
}
